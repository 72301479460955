<script>
import { GET_PREPARE_CULTURE_FROM_STATE } from '@/views/control/store/actions'
import { autoType } from '@/constants/auto-type'
import { dateForm } from '@/core'
import { mapGetters } from 'vuex'

export default {
  name: 'SupervisorTableCell',
  props: {
    row: {
      type: Object,
      default: () => {},
    },
    propKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      interval: null,
      second: 1,
    }
  },
  computed: {
    ...mapGetters({
      getPrepareCultures: GET_PREPARE_CULTURE_FROM_STATE,
    }),
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  created() {
    this.secondUpdate()
  },
  methods: {
    secondUpdate() {
      this.interval = setInterval(() => {
        this.second++
      }, 1000)
    },
    createTableCellLongest(createElement, prop) {
      const content = this.row[prop] || '-'

      const truck = autoType.filter(item => item.id === content)

      const name = truck.length > 0 && truck[0].name ? truck[0].name : '-'
      const time =
        this.row && this.row.waiting_time
          ? 'Время ожидания: ' + dateForm(this.row.waiting_time + this.second)
          : ''

      return [
        createElement('div', { class: ['longest'] }, [name]),
        createElement('div', { class: ['longest'] }, [time]),
      ]
    },
    createTableCellLongestMobile(createElement) {
      const time =
        this.row && this.row.waiting_time
          ? dateForm(this.row.waiting_time + this.second)
          : ''

      return [createElement('div', { class: ['longest'] }, [time])]
    },
    createTableCellCulture(createElement) {
      const { culture_id } = this.row
      const currentCulture = `${this.getPrepareCultures[culture_id]?.name}
       ${
         this.getPrepareCultures[culture_id]?.harvest_year
           ? this.getPrepareCultures[culture_id]?.harvest_year + ' года'
           : ''
       }
      `

      return [createElement('div', { class: ['culture'] }, [currentCulture])]
    },
    createTableCellDefault(createElement, prop) {
      const content = this.row[prop] || '-'

      return [createElement('div', { class: [prop] }, [content])]
    },
  },

  render(createElement) {
    let classNames = [this.propKey]
    let renderData = [this.data]

    if (Array.isArray(this.data)) {
      renderData = this.data.map(item => createElement('div', item))
    }

    switch (this.propKey) {
      case 'culture_id': {
        renderData = this.createTableCellCulture(createElement)
        break
      }
      case 'most_waiting_text':
      case 'boardsiders_waiting_text':
      case 'waiting_truck_type': {
        renderData = this.createTableCellLongest(createElement, this.propKey)
        break
      }
      case 'waiting_truck_type_mobile': {
        renderData = this.createTableCellLongestMobile(
          createElement,
          this.propKey,
        )
        break
      }

      default: {
        renderData = this.createTableCellDefault(createElement, this.propKey)
        break
      }
    }

    return createElement('div', { class: classNames }, renderData)
  },
}
</script>
